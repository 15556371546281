import React from 'react';
import { useInView } from 'react-intersection-observer';

import styles from './ProjectsList.module.scss';

//Components
import { AnchorButton } from '@components/Buttons/AnchorButton';
import { ProjectThumbnail } from '@components/ProjectThumbnail/ProjectThumbnail';
import { Container } from '@components/Utilities/Container/Container';
import { FadeInWhenVisible } from '@components/Animations/FadeInWhenVisible/FadeInWhenVisible';

export const ProjectsList = props => {
    const { title, projects = [], small, button, noPaddingTop } = props;
    const { ref, inView } = useInView({ threshold: 0.25, triggerOnce: true });

    if (!projects.length) return null;

    return (
        <section
            className={`${styles.projectsList} ${small ? styles.projectsListSmall : ''} ${
                noPaddingTop ? styles.projectsListNoPaddingTop : ''
            }`}
        >
            <Container>
                {title && (
                    <FadeInWhenVisible>
                        <h2 className="h3" dangerouslySetInnerHTML={{ __html: title }}></h2>
                    </FadeInWhenVisible>
                )}

                <div ref={ref} className={styles.projectsList__list}>
                    {projects.map((project, index) => {
                        const { title, thumbnail, path, live_demo } = project;

                        return (
                            <ProjectThumbnail
                                key={index}
                                title={title}
                                image={thumbnail.image}
                                backgroundColor={thumbnail.background_color}
                                path={path}
                                small={small}
                                liveDemo={live_demo}
                                index={index}
                                parentInView={inView}
                            />
                        );
                    })}
                </div>

                {button && (
                    <FadeInWhenVisible>
                        <AnchorButton {...button.button_props} href={button.href}>
                            {button.text}
                        </AnchorButton>
                    </FadeInWhenVisible>
                )}
            </Container>
        </section>
    );
};
