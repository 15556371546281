import React from 'react';

//Data
import { data } from '@data/en/pages/home';

//Components
import SEO from '@components/Seo/Seo';
import { Header } from '@components/Header/Header';
import { Hero } from '@components/Hero/Hero';
import { CTA } from '@components/CTA/CTA';
import { ProjectsList } from '@components/ProjectsList/ProjectsList';
import { FeaturedPartners } from '@components/FeaturedPartners/FeaturedPartners';
import { SuperPowers } from '@components/SuperPowers/SuperPowers';
import { Footer } from '@components/Footer/Footer';

const IndexPage = () => {
    const { seo, hero, super_powers, cta, featured_projects, featured_partners } = data;

    return (
        <>
            <SEO {...seo} path="/" />

            <Header white />

            <Hero small title={hero.title} text={hero.text} red />

            <SuperPowers title={super_powers.title} powers={super_powers.powers} />

            <CTA small title={cta.title} href={cta.href} />

            <ProjectsList
                small
                title={featured_projects.title}
                projects={featured_projects.projects}
                button={featured_projects.button}
            />

            <FeaturedPartners
                title={featured_partners.title}
                text={featured_partners.text}
                button={featured_partners.button}
            />

            <Footer />
        </>
    );
};

export default IndexPage;
