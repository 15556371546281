import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { useStaticQuery, graphql } from 'gatsby';
import { motion, useAnimation, useReducedMotion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

//Components
import Img from 'gatsby-image';
import { ConditionalWrapper } from '@components/Utilities/ConditionalWrapper/ConditionalWrapper';

//Styles
import styles from './ProjectThumbnail.module.scss';

//Images
import ArrowRightUpWhite from '@images/icons/arrow-right-up-white.svg';
import ExteralLinkWhite from '@images/icons/external-link-white.svg';

export const ProjectThumbnail = props => {
    const { title, image, backgroundColor, path, small, liveDemo, index, parentInView } = props;
    const controls = useAnimation();
    const { ref, inView } = useInView({ threshold: 0.2, triggerOnce: true });
    const shouldReduceMotion = useReducedMotion();
    const [matches, setMatches] = useState();

    useEffect(() => {
        if ((parentInView && small && !matches) || inView) {
            controls.start('visible');
        }
    }, [controls, parentInView, inView, small, matches]);

    useEffect(() => {
        const mediaMatch = window.matchMedia('(max-width: 575px)');
        setMatches(mediaMatch.matches);

        const handler = e => setMatches(e.matches);
        mediaMatch.addListener(handler);

        return () => mediaMatch.removeListener(handler);
    }, []);

    const variants = !shouldReduceMotion
        ? {
              hidden: {
                  y: small ? 50 : 0,
                  x: small ? 0 : index % 2 === 0 ? 50 : -50,
                  opacity: 0,
              },
              visible(delay) {
                  return {
                      y: 0,
                      x: 0,
                      opacity: 1,
                      transition: {
                          delay: small && !matches ? delay : 0,
                          duration: 1,
                      },
                  };
              },
          }
        : {};

    const imgData = useStaticQuery(graphql`
        query {
            images: allFile {
                edges {
                    node {
                        relativePath
                        name
                        childImageSharp {
                            fixed(width: 500, quality: 100) {
                                ...GatsbyImageSharpFixed_withWebp_noBase64
                            }
                        }
                        publicURL
                    }
                }
            }
        }
    `);

    const extension = image.path.match(/[^\\]*\.(\w+)$/)[1];
    const foundImage = imgData.images.edges.find(n => {
        return n.node.relativePath.includes(image.path);
    });

    return (
        <motion.article
            ref={ref}
            variants={variants}
            initial="hidden"
            animate={controls}
            custom={index * 0.25}
            className={`${styles.projectThumbnail} ${small ? styles.projectThumbnailSmall : ''}`}
            style={{ backgroundColor: backgroundColor }}
        >
            <ConditionalWrapper
                condition={path}
                wrapperOne={children => (
                    <Link to={path} className={styles.projectThumbnail__anchor}>
                        {children}
                    </Link>
                )}
                wrapperTwo={children => (
                    <a
                        href={liveDemo}
                        rel="noreferrer"
                        target="_blank"
                        className={`${styles.projectThumbnail__anchor} ${styles.projectThumbnail__anchorExternal}`}
                    >
                        {children}
                    </a>
                )}
            >
                {title && (
                    <h1
                        className={`${styles.projectThumbnail__title} ${small ? 'h5' : 'h4'}`}
                        dangerouslySetInnerHTML={{ __html: title }}
                    ></h1>
                )}

                <div className={styles.projectThumbnail__wrapper}>
                    {path ? (
                        <ArrowRightUpWhite className={styles.projectThumbnail__arrow} />
                    ) : (
                        <ExteralLinkWhite className={styles.projectThumbnail__arrow} />
                    )}

                    <div className={styles.projectThumbnail__imgWrapper}>
                        {(extension === 'svg' || extension === 'gif') && foundImage ? (
                            <img loading="lazy" src={foundImage.node.publicURL} alt={image.alt} />
                        ) : foundImage ? (
                            <Img
                                style={{
                                    position: 'absolute',
                                    display: 'block',
                                    width: '100%',
                                    height: small ? 'calc(100% + 2rem)' : 'calc(100% + 4rem)',
                                }}
                                imgStyle={{
                                    objectFit: 'contain',
                                    objectPosition: 'bottom right',
                                }}
                                className={styles.projectThumbnail__img}
                                fixed={foundImage.node.childImageSharp.fixed}
                                alt={image.alt}
                            />
                        ) : null}
                    </div>
                </div>
            </ConditionalWrapper>
        </motion.article>
    );
};
