export const data = {
    path: '/',
    seo: {
        title: 'Home',
        description:
            'The Pack is a digital agency dedicated to create web experiences that reflect your brand values and extend your real-world business.',
    },
    hero: {
        title: 'Fearless <br/><span>web strategy?</span> <br/>Game on.',
        text: [
            'The Pack is a digital agency specialising in unique and immersive online experiences. We’re dedicated to creating web experiences that reflect your brand values and extend your real-world business presence into the virtual realm.',
        ],
    },
    super_powers: {
        title: 'Super Powers',
        powers: [
            {
                title: 'Web strategy',
                text: [
                    'In a world of templates, why not be yourself? We sit down with you and listen, working together towards a web presence tailored to the needs and preferences of your target audience.',
                ],
                objective:
                    'Map user personas to desired outcomes and agree an approach that matches your business objectives.',
                services: ['Co-Creation', 'Creative strategy', 'Conceptying'],
            },
            {
                title: 'User experience',
                text: [
                    'A website is a medium, a product, a channel, a webshop — sometimes ‘all the above.’ Once the strategy is set we design an interface that meets the needs and expectations of your target users.',
                ],
                objective:
                    'Create a rewarding site experience that extends the physical experience of your brand into the virtual realm.',
                services: [
                    'Prototyping',
                    'User interface design',
                    'User experience design',
                    '3D',
                    'Animation',
                ],
            },
            {
                title: 'Development',
                text: [
                    'We select the right technology stack for the solution and execute the final build. You get an online asset that delivers the performance audiences demand, looks amazing, amplifies your branding, and integrates with your e-commerce backend.',
                ],
                objective: 'Bring the strategy to life and launch a premium web product.',
                services: [
                    'Wordpress',
                    'Next.js',
                    'React',
                    'Vue.js',
                    'WebGL / Three.js',
                    'Web animations',
                ],
            },
        ],
    },
    cta: {
        title: 'What drives great digital design? Fearless counsel, close collaboration, hard work — and a sense of fun.',
        href: '/contact/',
    },
    featured_projects: {
        title: '<span>Digitalising</span> we already did',
        projects: [
            {
                path: '/work/blast-galaxy',
                title: 'How to create an arcade experience in the browser? ',
                thumbnail: {
                    background_color: '#0F0C24',
                    image: {
                        path: 'projects/blast-galaxy/blast-galaxy-thumbnail.png',
                        alt: 'Blast Galaxy arcade',
                    },
                },
            },
            {
                path: '/work/van-wind-naar-watt',
                title: 'How to enhance education with a digital platform?',
                thumbnail: {
                    background_color: '#077FB0',
                    image: {
                        path: 'projects/van-wind-naar-watt/van-wind-naar-watt-thumbnail.png',
                        alt: 'Van wind naar watt',
                    },
                },
            },
            {
                title: 'How to support the business owners of Haarlem during a global lockdown?',
                thumbnail: {
                    background_color: '#c8293a',
                    image: {
                        path: 'projects/haarlemse-winkels/haarlemse-winkels-thumbnail.png',
                        alt: 'Haarlemse winkels',
                    },
                },
                live_demo: 'https://haarlemsewinkels.nl',
            },
        ],
        button: {
            text: 'View all',
            href: '/work',
            button_props: {
                primary: true,
                red: true,
            },
        },
    },
    featured_partners: {
        title: 'Your on-demand dev team',
        text: [
            'Want to scale your capabilities without growing headcount?  Along with work for clients we lend our skills and expertise to agencies too. Ask how we can become an extension of your team.',
        ],
        button: {
            text: 'Contact us',
            href: '/contact',
        },
    },
};
